import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GrLocation } from 'react-icons/gr';
import style from "./css/home.module.css";

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Container className={style.container}>
            <Row>
                <Col>
                    <h3>Hi, I am</h3>
                    <h1 className={style.name}>Natalie Vu</h1>
                    <h3 className={style.roleClm}>Software Developer<br /> UI/UX Designer</h3>
                    <br />
                    <h4><GrLocation /> Toronto, CA</h4>
                    <p className={style.quote}>"The only way to <span>DO GREAT WORK</span> is <br />to love what you do"
                        - Steve Jobs</p>
                    <Link to="/resume">
                        <Button className={style.viewProjBtn} variant="outline-primary">View My Resume</Button>
                    </Link> <br/>
                    <Link to="/about"> <Button className={style.viewProjBtn} variant="link">More about me</Button>
                    </Link>
                </Col>
                <Col className={style.pfpClm}><img alt="" className={style.pfp} src="pfp.png" /></Col>
            </Row>
        </Container>
    );
}
export default Home;