import React, { Fragment, useEffect, useState } from "react";
import { Container, Nav } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import style from "./css/projects.module.css";
import { useInView } from "react-intersection-observer";
import MyPortfolioProject from "./projects/MyPortfolioProject";
import StargazeCinemaProject from "./projects/StargazeCinemaProject";
import WeatherJetProject from "./projects/WeatherJetProject";
import EventureProject from "./projects/EventureProject";
import AirbnbProject from "./projects/AirbnbProject";
import Experience from "./components/Experience";

const PROJECTS = [
  {
    key: "airbnb",
    title: "Airbnb",
    renderComponent: (isActive, setActive) => (
      <AirbnbProject {...{ isActive, setActive }} />
    ),
  },
  {
    key: "eventure",
    title: "Eventure",
    renderComponent: (isActive, setActive) => (
      <EventureProject {...{ isActive, setActive }} />
    )
  },
  {
    key: "weather-jet",
    title: "WeatherJet",
    renderComponent: (isActive, setActive) => (
      <WeatherJetProject {...{ isActive, setActive }} />
    ),
  },
  {
    key: "stargaze-cinema",
    title: "Stargaze Cinema",
    renderComponent: (isActive, setActive) => (
      <StargazeCinemaProject {...{ isActive, setActive }} />
    ),
  }
  
];

function Resume() {
  const [activeProject, setActiveProject] = useState();
  const [mobile, setMobile] = useState(false);

  const updateDimensions = () => {
    const width = window.innerWidth;
    width < 420 ? setMobile(true) : setMobile(false);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    updateDimensions();
  }, []);

  const { ref, entry } = useInView({
    threshold: [...new Array(100)].map((val, idx) => idx / 100), // [0.00, 0.01, 0.02, ..., 0.99, 1.0]
  });

  return (
    <>
      <Container className={style.container} fluid>
        <p className={style.p}>RESUME <br />
          <span>Open my PDF resume
            <span className={style.here}
              style={{ color: "#61DAFB" }}
              onClick={() => window.open("https://drive.google.com/file/d/1qzsrczZkfwCU3UtDG1b1_j3rSXd8VWs8/view?usp=sharing", "_blank")}>
              <u> here</u>
            </span>
          </span>
        </p>
      </Container>
      <Container fluid>
        <Row className={style.container1}>
          <Experience />
        </Row>
        <Row className={style.container1}>
          <h1 className={style.h1experience}>Projects</h1>
          {mobile ? null :
            <Col className="pb-5" xs={2} ref={ref}>
              <Nav
                className="flex-column align-items-center justify-content-center sticky-top"
                style={{ height: entry?.intersectionRect.height }}
              >
                {PROJECTS.map((project) => (
                  <Button
                    key={project.key}
                    variant="link"
                    className={`${style.projectNavLink} ${activeProject === project.key ? style.active : style.inactive}`}
                    onClick={() => activeProject !== project.key && setActiveProject(project.key)}
                  >
                    {project.title}
                  </Button>
                ))}
              </Nav>
            </Col>}
          <Col>
            {PROJECTS.map((project) => (
              <Fragment key={project.key}>
                {
                  project.renderComponent(
                    activeProject === project.key, // isActive 
                    () => setActiveProject(project.key) // setActive()
                  )
                }
                <hr className={style.hr} />
              </Fragment>
            ))}
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Resume;
