import { BsBootstrapFill, BsFileEarmarkCode } from "react-icons/bs";
import { SiJavascript, SiReact } from "react-icons/si";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Project from "../components/Project";
import style from "../css/projects.module.css";
import OverlayTriggerComponent from "../components/OverlayTrigger";

const WeatherJet = ({ isActive, setActive }) => {
  return (
    <Project
      title="WeatherJet"
      imageProps={{ src: "./devices/weather.png" }}
      {...{ isActive, setActive }}
    >
      <p className={style.desc}>
        A web application to query real-time weather in any city. It maintains a
        list of previously visited cities in the user's local storage by
        clicking on “View more/less” on each city. <br />
        <br />
        The website is built with React by fetching data from OpenWeatherMap API
      </p>
      
      <OverlayTriggerComponent text="React" >
        <SiReact className={style.reactIcon} />{" "}
      </OverlayTriggerComponent>
      <OverlayTriggerComponent text="JavaScript" >
        <SiJavascript className={style.jsIcon} />{" "}
      </OverlayTriggerComponent>
      <OverlayTriggerComponent text="Bootstrap" >
        <BsBootstrapFill className={style.btsIcon} />{" "}
      </OverlayTriggerComponent>
      
      <br />
      <br />
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-bottom`}>View Source Code </Tooltip>}
      >
        <Button
          variant="link"
          onClick={() =>
            window.open("https://github.com/tnvu7/weatherapp", "_blank")
          }
        >
          <BsFileEarmarkCode className={style.Sourceicon} />{" "}
        </Button>
      </OverlayTrigger>
      <Button
        onClick={() =>
          window.open("https://weatherapp-cyan.vercel.app/", "_blank")
        }
      >
        Live
      </Button>
    </Project>
  );
};

export default WeatherJet;
