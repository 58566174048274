import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import style from './css/about.module.css';
import { SiJavascript, SiReact, SiSqlite, SiTypescript, SiXamarin, SiCsharp, SiMongodb, SiMysql, SiCss3, SiCplusplus, SiPostgresql } from 'react-icons/si';
import { DiAngularSimple, DiHtml5, DiJava, DiNodejsSmall } from 'react-icons/di';
import { IoLogoIonic } from 'react-icons/io';
import { AiFillGithub } from 'react-icons/ai';
import OverlayTriggerComponent from "./components/OverlayTrigger";
import ActivityCard from './components/ActivityCard';

function SecondCol1() {
    return (
      <>
        <p className={style.intro}>
          Hi, my name is Natalie! <br />
          <br />
          I am an enthusiastic, and fast-learning third-year software developer
          student, specializing in full stack development. I have a passion for
          UI/UX Design, and my ultimate goal is to code user-friendly,
          responsive websites and mobile applications.
          <br />
          <br />
        </p>
        <div className={style.whoamiClm}>
          <img alt="" className={style.aboutpfp} src="pfp.png" />
        </div>
      </>
    );
}

function SecondCol2() {
    return (
    <>
        <p className={style.intro}>
        It all started when I decided to leave my home country and come to
        Canada to study grade 12. <br />
        <br />
        First, I took Computer Science and I was the only girl in the class.
        Even though I have never done any coding before, it was one of the
        most interesting classes I have ever taken. <br />
        <br />
        After spending three years in the Software Developement program,
        every day I fell in love more with coding full stack projects and
        front-end using React, Angular. At the same time, I strive to create
        designs that are user-friendly and modern using Figma. Thinking
        back, I would never have found my passion if I never took a chance
        to try it! Life becomes so exciting when we dare to try out new
        things!
        </p>
    </>
    );
}

function AboutSection({ bullet, title, children }) {
    return (
      <Row className={style.row}>
        <Col md={12} lg={6}>
          <h1>
            <span>{bullet}</span>&nbsp; {title}
          </h1>
        </Col>
        <Col md={12} lg={6}>
          {children}
        </Col>
      </Row>
    );
}

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
      <div>
        <Container className={style.container} fluid>
          <p className={style.p}>
            ABOUT ME <br />
            <span>
              Open my PDF resume
              <a
                className={style.here}
                style={{ color: "#61DAFB" }}
                href="https://drive.google.com/file/d/1qzsrczZkfwCU3UtDG1b1_j3rSXd8VWs8/view?usp=sharing"
                target="_blank"
              >
                <u> here</u>
              </a>
            </span>
          </p>
        </Container>
        <Container className={style.container1}>
          <AboutSection bullet="01" title="WHO I AM">
            <SecondCol1 />
          </AboutSection>

          <AboutSection bullet="02" title="FINDING MY PASSION">
            <SecondCol2 />
          </AboutSection>

          <AboutSection bullet="03" title="THE OTHER SIDE OF ME">
          </AboutSection>

          <Row className={style.row}>
            <div className={style.about_activities}>
              <ActivityCard
                src="activities/tennis.jpg"
                title="Sports"
                description="Outside of work, you'll most likely find me at the tennis court. 
                            While I enjoy all sports such as ice skating, table tennis, or kickboxing, tennis 
                            remains my favorite. Feel free to hit me up!"
                date="2022 - Toronto"
              />
              <ActivityCard
                src="activities/piano.jpeg"
                title="Music"
                description="Music has been a big part of me ever since I started taking piano lessons at 7 years old. Growing up,
                            I'd use my musical skills including piano, guitar, ukulele, and singing to contribute to the church and giving piano 
                            lessons to family's friends."
                date="2022 - Toronto"
              />
            </div>
            <div className={style.about_activities}>
              <ActivityCard
                src="activities/travel.JPG"
                title="Travel"
                description="Being an adventurous person, I love travelling around the world to learn different cultures 
                                and admire how beautiful the earth is. I've set foot on 11 countries, and my best travel buddy is my mom! "
                date="2016 - Australia"
              />
              <ActivityCard
                src="activities/friends.JPG"
                title="Friends"
                description="Life is much more fun when friends are there for you!!! Thank you to my dearest Harsh, Justin, and 
                                Tue. I wouldn't have made it without you guys. I love you all!"
                date="2023 - Pursuit OCR"
              />
            </div>
          </Row>

          <h1>
            <span>04</span>&nbsp; LANGUAGES I SPEAK
          </h1>
          <Container className={style.skills}>
            <div>
              <h4 style={{ paddingBottom: "5px" }}>Front End</h4>

              <OverlayTriggerComponent text="React">
                <SiReact className={style.reactIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="Angular">
                <DiAngularSimple className={style.angularIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="JavaScript">
                <SiJavascript className={style.jsIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="Typescript">
                <SiTypescript className={style.blueIcon} />{" "}
              </OverlayTriggerComponent>
              
              <OverlayTriggerComponent text="Ionic">
                <IoLogoIonic className={style.ionicIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="Xamarin">
                <SiXamarin className={style.blueIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="HTML5">
                <DiHtml5 className={style.htmlIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="CSS3">
                <SiCss3 className={style.blueIcon} />{" "}
              </OverlayTriggerComponent>

              <hr className={style.hr} />

              <h4 style={{ paddingBottom: "5px" }}>Back End</h4>

              <OverlayTriggerComponent text="Java">
                <DiJava className={style.angularIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="C#">
                <SiCsharp className={style.blackIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="Node.js">
                <DiNodejsSmall className={style.jsIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="C++">
                <SiCplusplus className={style.blackIcon} />{" "}
              </OverlayTriggerComponent>

              <hr className={style.hr} />

              <h4 style={{ paddingBottom: "5px" }}>Database</h4>

              <OverlayTriggerComponent text="SQLite">
                <SiSqlite className={style.sqlIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="PostgreSql">
                <SiPostgresql className={style.blackIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="MongoDB">
                <SiMongodb className={style.jsIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="Mysql">
                <SiMysql className={style.htmlIcon} />{" "}
              </OverlayTriggerComponent>

              <hr className={style.hr} />

              <h4 style={{ paddingBottom: "5px" }}>Others</h4>

              <OverlayTriggerComponent text="Git/Github">
                <AiFillGithub className={style.blackIcon} />{" "}
              </OverlayTriggerComponent>
              <OverlayTriggerComponent text="Figma">
                <img alt="" style={{ height: "35px" }} src="logos_figma.png" />
              </OverlayTriggerComponent>
            </div>
          </Container>
        </Container>
      </div>
    );
}
export default About;
