import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Home from "./Home";
import Resume from "./Resume";
import About from "./About";
import Contact from "./Contact";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Offcanvas } from "react-bootstrap";
import SideOptions from "./components/SideOptions";

function Routing() {
    const [navbar, setNavbar] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const contactSectionRef = useRef(null);

    const updateDimensions = () => {
        const width = window.innerWidth;
        width < 420 ? setMobile(true) : setMobile(false);
    }

    const changeBackground = () => {
        setNavbar(window.scrollY >= 20)
    };

    // useEffect = componentDidMount + componenetDidUpdate + componentWillUnmount
    useEffect(() => {
        window.addEventListener('scroll', changeBackground, false);
        updateDimensions();
        return () => {
            window.removeEventListener('scroll', changeBackground, false);
        }
    }, []);

    const onContactClick = () => {
        window.scrollTo({
            top: contactSectionRef.current.getBoundingClientRect().top,
            behavior: "smooth",
        });
        setExpanded(false);
    }
    
    function Navigation() {
        return (
            <Nav id='navbarCollapse' fixed="top" activeKey="/" navbarScroll>
                <Nav.Item>
                    <Nav.Link
                    className="underline" as={Link} to="/"
                    style={{color: `${mobile ? "black" : null}`, fontSize: "20px"}}
                    onClick={() => setExpanded(false)}
                    >Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                    className="underline" as={Link} to="/resume"
                    style={{color: `${mobile ? "black" : null}`, fontSize: "20px"}}
                    onClick={() => setExpanded(false)}
                    >Resume</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                    className="underline" as={Link} to="/about"
                    style={{color: `${mobile ? "black" : null}`, fontSize: "20px"}}
                    onClick={() => setExpanded(false)}
                    >About</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                    className="underline" onClick={onContactClick}
                    style={{color: `${mobile ? "black" : null}`, fontSize: "20px"}}
                    >Contact</Nav.Link>
                </Nav.Item>
                {mobile ? <SideOptions/> : null}
            </Nav>
        )
    }
    function MobileNav() {
        return (
            <Navbar id={navbar ? 'headingonscroll' : 'headingnotscroll'} fixed="top" collapseOnSelect expand="true" expanded={expanded}>
                <Container>
                    <Navbar.Brand id="name" as={Link} to="/">Natalie Vu</Navbar.Brand>
                    <Navbar.Toggle 
                    style={{borderColor: "lightgray"}} 
                    data-toggle="collapse" data-target="#offcanvasNavbar" 
                    aria-expanded="false" aria-controls="offcanvasNavbar" 
                    onClick={() => setExpanded(expanded ? false : "expanded")}/>
                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                        placement="end">
                        <Offcanvas.Header closeButton onClick={() => setExpanded(expanded ? false : "expanded")}>
                            <Offcanvas.Title style={{fontSize: "22pt", fontWeight: "700"}} id="offcanvasNavbarLabel">Natalie Vu</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Navigation />
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        )
    }
    function DesktopNav() {
        return (
            <Navbar id={navbar ? 'headingonscroll' : 'headingnotscroll'} fixed="top">
                <Container>
                    <Navbar.Brand id="name" as={Link} to="/">Natalie Vu</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    
                        <Navigation />
                    
                </Container>
            </Navbar>
        )
    }
    return (
        <div>
            <Router>
                {mobile ? <MobileNav /> : <DesktopNav />}
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='resume' element={<Resume />} />
                    <Route path='about' element={<About />} />
                </Routes>
                <Contact ref={contactSectionRef} />
            </Router>
        </div >);
}

export default Routing;