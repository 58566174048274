import React from "react";
import { Row, Col } from "react-bootstrap";
import style from "../css/projects.module.css";

function Experience() {
  return (
    <div>
      <h1 className={style.h1experience}>Experience</h1>
      <div className={style.experienceContainer}>
        <Row className={style.row}>
          <Col>
            <h2>RBC</h2>
          </Col>
          <Col className={style.dateCol}>
            <h4>May 2022 - Aug 2022</h4>
          </Col>
          <h3>Software Developer Intern</h3>
          <ul>
            <li>
              Developed pages and features in a front-end micro service for I&TS
              IT using <b>Angular 12</b>. Resolved defects, wrote unit tests, delivered
              codes from development to production in <b>Jenkins, Sonar, Nexus</b>.
            </li>

            <li>
              Developed an <b>Enterprise UI component</b> in the Innersource/RIG
              library to be utilized across all RBC One applications.
            </li>
            <li>
              Volunteer to the T&O Student Program as a <b>Squad Lead</b>. Organized
              bi-weekly meetings to help Squad members network.
            </li>
            <li>
              One of 4 co-op students to showcase at the DX Swarm event – a
              collab of RBC Developer Experience x T&O.
            </li>
          </ul>
        </Row>
        <Row>
          <Col>
            <h2>LanguageLine Solutions</h2>
          </Col>
          <Col className={style.dateCol}>
            <h4>Jun 2021 - Apr 2022</h4>
          </Col>
          <h3>Vietnamese Interpreter</h3>
          <ul>
            <li>
              Translate live on the phone between Vietnamese and English for up
              to 20 clients a day (hospitals, clinics, banks, courts, social
              services) in the US, Canada, and the UK.
            </li>
            <li>
              Quality Control Manager issued an excellent interpretation
              evaluation due to 9 months with 100% customer satisfaction.
            </li>
          </ul>
        </Row>
      </div>
    </div>
  );
}

export default Experience;
