import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Project from "../components/Project";
import style from "../css/projects.module.css";
import { BsFileEarmarkCode } from "react-icons/bs";
import { SiSqlite } from "react-icons/si";
import { DiAngularSimple } from "react-icons/di";
import { IoLogoIonic } from "react-icons/io";
import OverlayTriggerComponent from "../components/OverlayTrigger";

const StargazeCinema = ({ isActive, setActive }) => {
  return (
    <Project
      title="Stargaze Cinema"
      imageProps={{ src: "./devices/stargaze.png" }}
      {...{ isActive, setActive }}
    >
      <p className={style.desc}>
        A cross-platform mobile application (Android and IOS) to search for
        movies, save the movie to a local device, as well as removing the movie
        from database. <br />
        <br />
        The mobile app is developed with Ionic and Angular (Typescript, SCSS).
        The data is retrieved through OMDB API, and the user’s added movies are
        saved into the local device’s storage by SQLite and Cordova plugin
      </p>

      <OverlayTriggerComponent text="Angular" >
        <DiAngularSimple className={style.angularIcon} />{" "}
      </OverlayTriggerComponent>
      <OverlayTriggerComponent text="Ionic" >
        <IoLogoIonic className={style.ionicIcon} />{" "}
      </OverlayTriggerComponent>
      <OverlayTriggerComponent text="SQLite" >
        <SiSqlite className={style.sqlIcon} />
      </OverlayTriggerComponent>
      <br />
      <br />
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-bottom`}>View Source Code </Tooltip>}
      >
        <Button
          variant="link"
          onClick={() =>
            window.open("https://github.com/tnvu7/StargazeCinema", "_blank")
          }
        >
          <BsFileEarmarkCode className={style.Sourceicon} />{" "}
        </Button>
      </OverlayTrigger>
      <Button
        onClick={() =>
          window.open("https://www.youtube.com/watch?v=sIeR8_ty6y0", "_blank")
        }
      >
        Demo
      </Button>
    </Project>
  );
};

export default StargazeCinema;
