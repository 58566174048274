import { useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import style from "../css/projects.module.css";
import { useInView } from "react-intersection-observer";

const Project = ({ title, imageProps, isActive, setActive, children }) => {
  const wrapperRef = useRef(null);
  const [mobile, setMobile] = useState(false);

  const updateDimensions = () => {
    const width = window.innerWidth;
    width < 420 ? setMobile(true) : setMobile(false);
  }
  useEffect(() => {
    updateDimensions();
  }, []);
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView && !isActive) {
      setActive();
    }
  }, [inView]);

  useEffect(() => {
    if (isActive && !inView) {
      window.scrollBy({
        left: 0,
        top: wrapperRef.current?.getBoundingClientRect().top,
        behavior: "smooth",
      });
    }
  }, [isActive]);
  function SecondCol() {
    return (
      <Col className="d-flex justify-content-center">
        <img
          id="devicemockup"
          alt=""
          className={`${title === "Stargaze Cinema" | title === "Eventure" ? style.mobdevices : style.devices}`}
          {...imageProps} />
      </Col>
    )
  }
  return (
    <div ref={wrapperRef}>
      <Row className={style.theProject} ref={ref}>
        <h1 className={style.head}>{title}</h1>
        <Col >{children}</Col>
        {title == "Stargaze Cinema" | title == "Eventure" && mobile ?
          <Row>
            <SecondCol />
          </Row> :
          <SecondCol />
        }

      </Row>
    </div>
  );
};

export default Project;
