import React from 'react'
import style from '../css/card.module.css'

function ActivityCard({ src, title, description, date}) {
  return (
    <div className={style.card}>
      <img className={style.img} src={src} alt="" />
      <div className={style.card_info}>
          <h2 className={style.title}>{title}</h2>
          <h3 className={style.desc}>{description}</h3> 
          <h4 className={style.date}>{date}</h4>
      </div>
  </div>
  )
}

export default ActivityCard