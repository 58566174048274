import React, { forwardRef } from "react";
import { BiCopyright } from "react-icons/bi";
import Container from "react-bootstrap/Container";
import { SiReact } from "react-icons/si";
import { FaLinkedin } from "react-icons/fa";
import { AiFillGithub, AiOutlineMail } from "react-icons/ai";
import style from "./css/contact.module.css";
import OverlayTriggerComponent from "./components/OverlayTrigger";

const Contact = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <Container fluid className={style.fluid}>
        <div className={style.contactSection}>
          <h1 className={style.heading}>GET IN TOUCH</h1>
          <br />
          <OverlayTriggerComponent text="LinkedIn">
            <a
              className={style.a}
              href="https://www.linkedin.com/in/natalie-vu-6a7ab9167/"
              target="_blank"
            >
              <FaLinkedin className={style.icon} />{" "}
            </a>
          </OverlayTriggerComponent>
          <OverlayTriggerComponent text="Github">
            <a
              className={style.a}
              href="https://github.com/tnvu7"
              target="_blank"
            >
              <AiFillGithub className={style.icon} />{" "}
            </a>
          </OverlayTriggerComponent>
          <OverlayTriggerComponent text="Email">
            <a
              className={style.a}
              href="mailto:vuthunga2001@gmail.com"
              target="_blank"
            >
              <AiOutlineMail className={style.icon} />{" "}
            </a>
          </OverlayTriggerComponent>
        </div>
        <br />
      </Container>
      <footer>
        <BiCopyright /> Natalie Vu, 2022 using &nbsp;{" "}
        <SiReact className={style.reactIcon} />
      </footer>
    </div>
  );
});

export default Contact;
