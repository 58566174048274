import Button from "react-bootstrap/Button";
import Project from "../components/Project";
import style from "../css/projects.module.css";
import { SiJavascript, SiReact, SiMaterialui } from "react-icons/si";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { BsBootstrapFill, BsFileEarmarkCode } from "react-icons/bs";
import OverlayTriggerComponent from "../components/OverlayTrigger";

const AirbnbProject = ({ isActive, setActive }) => {
    return (
        <Project
      title="Airbnb Clone"
      imageProps={{ src: "./devices/airbnb.png" }}
      {...{ isActive, setActive }}
    >
      <p className={style.desc}>
      Airbnb clone is a React web application to browse for staycation options. This is a front-end demo only. <br />
        <br />
        The app is developed with React, utilizes the Date Picker library and Material UI.
      </p>
      <OverlayTriggerComponent text="React" >
        <SiReact className={style.reactIcon} />{" "}
      </OverlayTriggerComponent>
      <OverlayTriggerComponent text="JavaScript" >
        <SiJavascript className={style.jsIcon} />{" "}
      </OverlayTriggerComponent>
      <OverlayTriggerComponent text="Material UI" >
        <SiMaterialui className={style.blackIcon} />
      </OverlayTriggerComponent>
      <br />
      <br />
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={`tooltip-bottom`}>View Source Code </Tooltip>}
      >
        <Button
          variant="link"
          onClick={() =>
            window.open("https://github.com/tnvu7/airbnbClone", "_blank")
          }
        >
          <BsFileEarmarkCode className={style.Sourceicon} />{" "}
        </Button>
      </OverlayTrigger>
      <Button
        onClick={() =>
          window.open("https://airbnb-clone-tnvu7.vercel.app/", "_blank")
        }
      >
        Live
      </Button> &nbsp;&nbsp;
      <Button
        onClick={() =>
          window.open("https://www.youtube.com/watch?v=P3TPptB5cKk", "_blank")
        }
      >
        Demo
      </Button>
    </Project>
    )
}

export default AirbnbProject;