import style from "../css/sideops.module.css";
import { CgFileDocument } from "react-icons/cg";
import { Container } from "react-bootstrap";
import { FaLinkedinIn } from "react-icons/fa";
import { AiFillGithub, AiOutlineMail } from "react-icons/ai";
import { BiCopyright } from "react-icons/bi";

function SideOptions() {
  return (
    <div>
      <Container className={style.container}>
        <button className={style.resume}>
          <CgFileDocument className={style.icon} />
          <span className={style.textbtn}>
            <a
              className={style.a}
              href="https://drive.google.com/file/d/1SaZTK49kFnrbtx5gws9vthv2m74OlcRl/view?usp=sharing"
              target="_blank"
            >
              MY RESUME
            </a>
          </span>
        </button>
        <br />
        <button className={style.linkedin}>
          <FaLinkedinIn className={style.LinkedInicon} />
          <span className={style.textbtn}>
            <a
              className={style.a}
              href="https://www.linkedin.com/in/natalie-vu-6a7ab9167/"
              target="_blank"
            >
              MY LINKEDIN{" "}
            </a>
          </span>
        </button>
        <br />
        <button className={style.github}>
          <AiFillGithub className={style.icon} />
          <span className={style.textbtn}>
            <a
              className={style.a}
              href="https://github.com/tnvu7"
              target="_blank"
            >
              MY GITHUB
            </a>
          </span>
        </button>
        <br />
        <button className={style.email}>
          <AiOutlineMail className={style.msgIcon} />
          <span className={style.textbtn}>
            <a
              className={style.a}
              href="mailto:vuthunga2001@gmail.com"
              target="_blank"
            >
              SEND MESSAGE
            </a>
          </span>
        </button>
        <br />
      </Container>
      <p className={style.sideNavfooter}>
        <BiCopyright /> Natalie Vu, 2022{" "}
      </p>
    </div>
  );
}

export default SideOptions;
