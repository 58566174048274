import Button from "react-bootstrap/Button";
import Project from "../components/Project";
import style from "../css/projects.module.css";
import { SiSqlite } from "react-icons/si";
import { DiAngularSimple } from "react-icons/di";
import { IoLogoIonic } from "react-icons/io";
import OverlayTriggerComponent from "../components/OverlayTrigger";

const EventureProject = ({ isActive, setActive }) => {
  return (
    <Project
      title="Eventure"
      imageProps={{ src: "./devices/eventure.png" }}
      {...{ isActive, setActive }}
    >
      <p className={style.desc}>
        A cross-platform mobile app to browse for points of interest such as attractions, restaurants, stores. The user can create, attend events, and customize their own profile. <br />
        <br />
        The app is developed with Ionic and Angular (Typescript, SCSS). It uses TomTom API for locations and Firebase for database.
      </p>
      <OverlayTriggerComponent text="Angular" >
        <DiAngularSimple className={style.angularIcon} />{" "}
      </OverlayTriggerComponent>
      <OverlayTriggerComponent text="Ionic" >
        <IoLogoIonic className={style.ionicIcon} />{" "}
      </OverlayTriggerComponent>
      <OverlayTriggerComponent text="SQLite" >
        <SiSqlite className={style.sqlIcon} />
      </OverlayTriggerComponent>
      <br />
      <br />
      <Button
        onClick={() =>
          window.open("https://www.youtube.com/watch?v=r-ZLR86G9oo", "_blank")
        }
      >
        Demo
      </Button>
    </Project>
  )
}
export default EventureProject;